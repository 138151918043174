<template>
  <div>
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>
    <b-card-actions
      v-if="!full_screen"
      action-collapse
      :title="lang('t_reports')"
    >
      <b-row>
        <b-col md="3" sm="4">
          <b-form-group :label="lang('t_category')">
            <v-select
              appendToBody
              :placeholder="lang('t_pleaseSelect')"
              v-model="selected_category"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              deselectFromDropdown
              label="display_name"
              :reduce="(item) => item.internal_name"
              :options="categories"
              @input="clear_report"
              :clearable="false"
            /> </b-form-group
        ></b-col>
        <b-col md="3" sm="4" v-if="selected_category">
          <b-form-group :label="lang('t_report')">
            <v-select
              appendToBody
              :placeholder="lang('t_pleaseSelect')"
              v-model="selected_report"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              deselectFromDropdown
              label="display_name"
              :options="
                report_list.filter((e) => e.category == selected_category)
              "
              :clearable="false"
              @input="clear_data"
            /> </b-form-group
        ></b-col>
        <b-col v-if="selected_report && selected_report.variants" md="3" sm="4">
          <b-form-group :label="lang('t_reportGroup')">
            <v-select
              appendToBody
              :placeholder="lang('t_pleaseSelect')"
              v-model="selected_variant"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              deselectFromDropdown
              label="display_name"
              :reduce="(item) => item.internal_name"
              :options="selected_report.variants"
            /> </b-form-group
        ></b-col>

        <b-col md="3" sm="4" v-if="selected_report">
          <b-form-group :label="lang('t_date')">
            <date-range-picker
              :opens="'left'"
              append-to-body
              class="d-block"
              ref="picker"
              :autoApply="true"
              :locale-data="{
                direction: 'ltr',
                format: 'dd/mm/yyyy HH:mm',
                separator: ' / ',
                applyLabel: 'Uygula',
                cancelLabel: 'İptal',
                weekLabel: 'H',
                customRangeLabel: 'Özel Aralık',
                daysOfWeek: ['Pz', 'Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct'],
                monthNames: [
                  'Oca',
                  'Şub',
                  'Mar',
                  'Nis',
                  'May',
                  'Haz',
                  'Tem',
                  'Ağu',
                  'Eyl',
                  'Eki',
                  'Kas',
                  'Ara',
                ],
                firstDay: 1,
              }"
              v-model="filter_values.dateRanges"
              :timePicker="true"
              :timePicker24Hour="true"
              :time-picker-seconds="false"
            >
              <template v-slot:input="picker">
                <span>
                  {{ setDateFormat(picker.startDate) | date }} -
                  {{ setDateFormat(picker.endDate) | date }}
                </span>
              </template>
              <template #ranges="ranges">
                <div class="ranges">
                  <b-row
                    class="mx-25"
                    v-for="(range, name) in ranges.ranges"
                    :key="name"
                  >
                    <b-col>
                      <b-button
                        size="sm"
                        block
                        @click="customClickRange(range)"
                        variant="flat-primary"
                      >
                        {{ name }}
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
                <!-- <div class="ranges">
                  <ul>
                    <li v-for="(range, name) in ranges.ranges" :key="name">
                     
                      <b>{{ name }}</b>
                      <small class="text-muted"
                        >{{ range[0].toDateString() }} -
                        {{ range[1].toDateString() }}</small
                      >
                    </li>
                  </ul>
                </div> -->
              </template>
              <div slot="footer" slot-scope="data" class="slot">
                <div
                  class="border-top px-1 py-1 d-flex justify-content-between align-items-center"
                >
                  <div>
                    {{ data.rangeText }}
                  </div>
                  <div class="float-right">
                    <b-button
                      size="sm"
                      @click="
                        picker.setStartDate({});
                        picker.setEndDate({});
                      "
                      variant="flat-secpndary"
                    >
                      {{ lang("t_clear") }}
                    </b-button>
                    <b-button
                      size="sm"
                      @click="data.clickApply"
                      v-if="!data.in_selection"
                      variant="flat-primary"
                    >
                      {{ lang("t_apply") }}
                    </b-button>
                    <!-- <a
                     
                      class="btn btn-flat btn-primary btn-sm"
                      ></a
                    > -->
                  </div>
                </div>
              </div>
            </date-range-picker>
            <!-- <flat-pickr
              v-model="filter_values.startDate"
              class="form-control"
              :disabled="selected_date_filter_type != 'between_dates'"
              :config="{
                mode: 'range',
                enableTime: true,
                dateFormat: 'Y-m-d H:i',
                allowInput: true,
                time_24hr: true,
              }"
            >
            </flat-pickr> -->
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3" :key="i" v-for="(item, i) in selected_report.filters">
          <b-form-group v-if="item.internal_name == 'QualityForm'">
            <v-select
              :closeOnSelect="false"
              appendToBody
              v-model="filter_values.selectedQualityForms"
              multiple
              :placeholder="lang(item.display_name)"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              deselectFromDropdown
              label="display_name"
              :reduce="(item) => item._id"
              :options="quality_forms"
            />
          </b-form-group>
          <b-form-group v-if="item.internal_name == 'Project'">
            <v-select
              :closeOnSelect="false"
              appendToBody
              v-model="filter_values.selectedProjects"
              multiple
              :placeholder="lang(item.display_name)"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              deselectFromDropdown
              label="display_name"
              :reduce="(item) => item.internal_name"
              :options="projects"
            />
          </b-form-group>
          <b-form-group v-if="item.internal_name == 'Queue'">
            <v-select
              :closeOnSelect="false"
              appendToBody
              v-model="filter_values.selectedQueues"
              multiple
              :placeholder="lang(item.display_name)"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              deselectFromDropdown
              label="display_name"
              :reduce="(item) => item.internal_name"
              :options="queues"
            />
          </b-form-group>

          <b-form-group v-if="item.internal_name == 'Verdict'">
            <v-select
              :closeOnSelect="false"
              appendToBody
              v-model="filter_values.selectedVerdicts"
              multiple
              :placeholder="lang(item.display_name)"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              deselectFromDropdown
              label="finish_code"
              :reduce="(item) => item.finish_code"
              :options="finish_codes"
            />
          </b-form-group>
          <b-form-group v-if="item.internal_name == 'TerminateSide'">
            <v-select
              :closeOnSelect="false"
              appendToBody
              v-model="filter_values.selectedTerminateSides"
              multiple
              :placeholder="lang(item.display_name)"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              deselectFromDropdown
              label="display_name"
              :reduce="(item) => item.internal_name"
              :options="[
                { internal_name: 'local', display_name: 'local' },
                { internal_name: 'remote', display_name: 'remote' },
              ]"
            />
          </b-form-group>
          <b-form-group v-if="item.internal_name == 'NAQueueFilter'">
            <b-form-checkbox
              v-model="filter_values.dontGetNoQueueRecords"
              class="custom-control-primary mt-75"
            >
              <span class="vs-checkbox">
                <span class="vs-checkbox--check">
                  <!-- <i class="vs-icon feather icon-check" /> -->
                  {{ lang("t_dontGetNoQueueRecords") }}
                </span>
              </span>
            </b-form-checkbox>
          </b-form-group>
          <b-form-group v-if="item.internal_name == 'Direction'">
            <v-select
              :closeOnSelect="false"
              appendToBody
              v-model="filter_values.selectedDirections"
              multiple
              :placeholder="lang(item.display_name)"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              deselectFromDropdown
              label="display_name"
              :reduce="(item) => item.internal_name"
              :options="[
                { internal_name: 'inbound', display_name: 'Inbound' },
                { internal_name: 'outbound', display_name: 'Outbound' },
                { internal_name: 'dialer', display_name: 'Dialer' },
                { internal_name: 'text', display_name: 'Text' },
                { internal_name: 'manual', display_name: 'Manual' },
              ]"
            />
          </b-form-group>
          <b-form-group v-if="item.internal_name == 'Campaign'">
            <v-select
              :closeOnSelect="false"
              appendToBody
              v-model="filter_values.selectedCampaigns"
              multiple
              :placeholder="lang(item.display_name)"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              deselectFromDropdown
              label="display_name"
              :reduce="(item) => item.internal_name"
              :options="campaigns"
            />
          </b-form-group>
          <b-form-group v-if="item.internal_name == 'Line'">
            <v-select
              :closeOnSelect="false"
              appendToBody
              v-model="filter_values.selectedLines"
              multiple
              :placeholder="lang(item.display_name)"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              deselectFromDropdown
              label="display_name"
              :reduce="(item) => item.caller_number"
              :options="lines"
            />
          </b-form-group>
          <b-form-group v-if="item.internal_name == 'IVR'">
            <v-select
              appendToBody
              v-model="filter_values.selectedIvr"
              :placeholder="lang(item.display_name)"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              deselectFromDropdown
              label="display_name"
              :reduce="(item) => item.internal_name"
              :options="ivr_list"
              :multiple="
                selected_report.internal_name == 'CallDetails' ? true : false
              "
            />
          </b-form-group>
          <b-form-group v-if="item.internal_name == 'Agent'">
            <v-select
              :closeOnSelect="false"
              appendToBody
              v-model="filter_values.selectedAgents"
              multiple
              :placeholder="lang(item.display_name)"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              deselectFromDropdown
              label="username"
              :reduce="(item) => item.username"
              :options="agents"
            />
          </b-form-group>
          <b-form-group v-if="item.internal_name == 'AgentGroup'">
            <v-select
              :closeOnSelect="false"
              appendToBody
              v-model="filter_values.selectedAgentGroups"
              multiple
              :placeholder="lang(item.display_name)"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              deselectFromDropdown
              label="display_name"
              :reduce="(item) => item"
              :options="agent_groups"
              @input="onAgentGroupChange"
            />
          </b-form-group>
          <b-form-group v-if="item.internal_name == 'State'">
            <v-select
              :closeOnSelect="false"
              v-model="filter_values.selectedStates"
              multiple
              :placeholder="lang(item.display_name)"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              deselectFromDropdown
              label="display_name"
              :reduce="(item) => item.internal_name"
              :options="StateOptions"
            />
          </b-form-group>
          <b-form-group v-if="item.internal_name == 'RemoteNumber'">
            <b-form-input
              v-model="filter_values.selectedNumber"
              :placeholder="lang(item.display_name)"
              @update="
                () => {
                  filter_values.selectedNumber = filter_values.selectedNumber
                    .split(' ')
                    .join('');
                }
              "
              @input="
                () => {
                  filter_values.selectedNumber = filter_values.selectedNumber
                    .split(' ')
                    .join('');
                }
              "
            />
          </b-form-group>
          <b-form-group v-if="item.internal_name == 'SLDuration'">
            <b-form-input
              type="number"
              v-model="filter_values.selectedSL"
              :placeholder="lang(item.display_name)"
              @update="
                () => {
                  filter_values.selectedSL = filter_values.selectedSL
                    .split(' ')
                    .join('');
                }
              "
              @input="
                () => {
                  filter_values.selectedSL = filter_values.selectedSL
                    .split(' ')
                    .join('');
                }
              "
            />
          </b-form-group>
          <b-form-group v-if="item.internal_name == 'title'">
            <b-form-input
              v-model="filter_values.title"
              :placeholder="lang(item.display_name)"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            v-if="selected_report.internal_name == 'DataSummaryByDynamicFields'"
          >
            <v-select
              v-model="filter_values.selectedDynamicCRMColumns.field1"
              :placeholder="lang('t_primaryKey')"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              deselectFromDropdown
              label="text"
              :reduce="(item) => item.value"
              :options="project_columns"
            >
            </v-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            v-if="selected_report.internal_name == 'DataSummaryByDynamicFields'"
          >
            <v-select
              v-model="filter_values.selectedDynamicCRMColumns.field2"
              :placeholder="lang('t_secondaryKey')"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              :reduce="(item) => item.value"
              :options="project_columns"
            >
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-if="selected_report.internal_name == 'QualityDetails'"
          cols="3"
        >
          <b-form-checkbox
            v-model="filter_values.useDatesForEvaluateDate"
            class="custom-control-primary mt-75"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                <!-- <i class="vs-icon feather icon-check" /> -->
                {{ lang("t_useDatesForEvaluateDate") }}
              </span>
            </span>
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-group
            v-if="
              selected_report.internal_name == 'CallDetails' ||
              selected_report.internal_name == 'QualityDetails'
            "
          >
            <v-select
              v-model="filter_values.selectedCustomerColumns"
              multiple
              :placeholder="lang('t_addExtraCustomerInfo')"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              :reduce="(item) => item"
              :options="project_columns"
              :closeOnSelect="false"
              deselectFromDropdown
            >
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <v-select
            :placeholder="lang('t_timeFormat')"
            v-model="selected_time_format"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="display_name"
            :reduce="(item) => item.internal_name"
            :options="report_time_types"
            :clearable="false"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            @click="getReportData"
            class="float-right"
            variant="primary"
          >
            {{ lang("t_get") }}
          </b-button>
          <b-button
            @click="SaveReportFilters"
            class="float-right mr-1"
            variant="secondary"
          >
            {{ lang("t_saveFilters") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card-actions>

    <b-row v-if="reports_in_queue.length > 0 && !full_screen">
      <b-col>
        <b-card
          style="max-height: 20rem; overflow-y: auto"
          v-if="reports_in_queue"
          no-body
          class="card-company-table"
        >
          <b-table
            :items="reports_in_queue"
            :fields="reports_in_queue_fields"
            class="mb-0"
          >
            <template #cell(CreateDate)="data">
              {{ setDateFormat(data.value) }}
            </template>
            <template #cell(Action)="data">
              <center>
                <b-button
                  v-if="data.item.Status == 'Done'"
                  size="sm"
                  :href="`${PROTOCOL}://${API_URL}/report/v1/ReportsInQueueById/${data.item._id}`"
                  variant="flat-secondary"
                  target="_blank"
                >
                  <feather-icon icon="DownloadIcon"></feather-icon>
                </b-button>
              </center>
            </template>
            <template #cell(Status)="data">
              <!-- Failed
"In Queue"
"In Progress"
Done -->

              <b-badge :variant="'light-' + colors[data.value]">
                <b-spinner
                  v-if="data.value == 'In Progress'"
                  :variant="'success'"
                  small
                  type="grow"
                />
                <b-icon
                  v-if="data.value == 'Failed'"
                  variant="danger"
                  scale="1.5"
                  icon="exclamation"
                ></b-icon>
                <b-icon
                  v-if="data.value == 'Done'"
                  variant="success"
                  scale="1.5"
                  icon="check"
                ></b-icon>

                <b-icon
                  v-if="data.value == 'In Queue'"
                  variant="warning"
                  scale="1"
                  icon="clock"
                ></b-icon>
                <span class="ml-50">
                  {{ description[data.value] }}
                </span>
              </b-badge>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>

    <b-card v-if="selected_report && fields.length > 0" no-body>
      <b-card-header v-if="getFullScreen" class="d-block">
        <b-card-title>
          {{ lang("t_reports") }}
        </b-card-title>
        <b-card-sub-title class="mt-1">
          {{ lang("t_reports") }}
        </b-card-sub-title>
      </b-card-header>

      <b-card-body class="px-0">
        <b-card-text class="d-flex mx-2">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>

            <b-form-input
              v-model="searchQuery"
              :placeholder="lang('t_search')"
            ></b-form-input>
          </b-input-group>

          <b-button
            @click="onexport"
            size="sm"
            class="px-50 py-50 ml-2"
            variant="flat-secondary"
          >
            <b-icon icon="download"></b-icon>
          </b-button>
          <b-dropdown
            @hidden="SaveReportFields"
            boundary="viewport"
            variant="flat-secondary"
            no-caret
            :right="$store.state.appConfig.isRTL"
            toggle-class="px-50 py-50"
          >
            <template #button-content>
              <i class="bi bi-funnel font-medium-1"></i>
            </template>

            <b-dropdown-form>
              <div
                style="
                  max-height: 20rem;
                  max-width: 50rem;
                  overflow-y: auto;
                  overflow-x: auto;
                "
              >
                <b-form-checkbox
                  @changed="fieldsVisibiltyChanged"
                  v-for="(item, i) in fields"
                  :key="i"
                  v-model="item.visible"
                >
                  <span class="text-truncate align-middle ml-1">{{
                    item.label
                  }}</span>
                </b-form-checkbox>
              </div>
            </b-dropdown-form>
          </b-dropdown>
          <b-button
            @click="full_screen = !full_screen"
            size="sm"
            class="px-50 py-50"
            variant="flat-secondary"
          >
            <b-icon
              :icon="
                !full_screen ? 'arrows-angle-expand' : 'arrows-angle-contract'
              "
            ></b-icon>
          </b-button>
        </b-card-text>
        <b-row class="mx-0" style="display: grid">
          <b-table
            v-model="visibleRows"
            style="width: 100%; overflow: auto"
            :filter="searchQuery"
            small
            hover
            :fields="fields.filter((field) => field.visible == true)"
            :items="items"
            responsive
            show-empty
            :empty-text="lang('t_noRecord')"
            :busy="filteredItems.length == 0"
            :per-page="perPage"
            :current-page="currentPage"
            @row-dblclicked="showCallDetails"
          >
            <template v-if="grandTotal" #bottom-row>
              <td
                class="text-nowrap"
                v-for="(field, index) in fields.filter(
                  (field) => field.visible == true
                )"
                :key="index"
              >
                <span class="font-weight-bolder text-nowrap">
                  {{ grandTotal[field.key] }}
                </span>
              </td>
            </template>
            <template #empty="scope">
              <b-container class="mt-5">
                <b-row>
                  <b-col class="text-center text-muted mt-50">
                    <span>{{ scope.emptyText }}</span>
                  </b-col>
                </b-row>
              </b-container>
            </template>
            <template #cell(RecordingFilePath)="data">
              <center>
                <b-button
                  v-if="data.item.RecordingFilePath != ''"
                  size="sm"
                  @click="openSecLineAudio(data.item)"
                  variant="outline-primary"
                >
                  <feather-icon icon="PlayIcon"></feather-icon>
                </b-button>
              </center>
            </template>
            <template #cell(Queue)="data">
              <b-badge variant="light-primary">
                {{
                  queues.find((e) => e.internal_name == data.value)
                    ? queues.find((e) => e.internal_name == data.value)
                        .display_name
                    : data.value
                }}
              </b-badge>
            </template>
            <template #cell(queue)="data">
              <b-badge variant="light-primary">
                {{
                  queues.find((e) => e.internal_name == data.value)
                    ? queues.find((e) => e.internal_name == data.value)
                        .display_name
                    : data.value
                }}
              </b-badge>
            </template>
            <template #cell(Kuyruk)="data">
              <b-badge variant="light-primary">
                {{
                  queues.find((e) => e.internal_name == data.value)
                    ? queues.find((e) => e.internal_name == data.value)
                        .display_name
                    : data.value
                }}
              </b-badge>
            </template>
            <template #cell(CalledBackedQueue)="data">
              <b-badge v-if="data.value" variant="light-primary">
                {{
                  queues.find((e) => e.internal_name == data.value)
                    ? queues.find((e) => e.internal_name == data.value)
                        .display_name
                    : data.value
                }}
              </b-badge>
            </template>
            <template #cell(IsCalledBack)="data">
              <center>
                <feather-icon
                  :icon="data.value ? 'CheckIcon' : 'XIcon'"
                  size="14"
                  :class="data.value ? 'text-secondary' : 'text-secondary'"
                />
              </center>
            </template>
            <template #cell(Campaign)="data">
              {{
                campaigns.find((e) => e.internal_name == data.value)
                  ? campaigns
                      .find((e) => e.internal_name == data.value)
                      .display_name.replace(/ /g, "\u00a0")
                  : data.value
              }}
            </template>
            <template #cell(Status)="data">
              {{
                statu_description.hasOwnProperty(data.value)
                  ? statu_description[data.value]
                  : data.value
              }}
            </template>
            <template #cell(LastIVRName)="data">
              <b-badge variant="light-primary">
                {{
                  ivr_list.find((e) => e.internal_name == data.value)
                    ? ivr_list.find((e) => e.internal_name == data.value)
                        .display_name
                    : data.value
                }}
              </b-badge>
            </template>
            <template #cell(Direction)="data">
              <div class="d-flex align-items-center">
                <b-icon
                  icon="telephone-inbound"
                  v-if="data.value == 'inbound'"
                  v-b-tooltip.hover.bottom="data.value"
                  v-b-tooltip.hover.v-secondary
                ></b-icon>
                <b-icon
                  icon="telephone-outbound"
                  v-if="data.value == 'outbound'"
                  v-b-tooltip.hover.bottom="data.value"
                  v-b-tooltip.hover.v-secondary
                ></b-icon>
                <b-icon
                  icon="telephone-outbound"
                  v-if="data.value == 'dialer'"
                  v-b-tooltip.hover.bottom="data.value"
                  v-b-tooltip.hover.v-secondary
                ></b-icon>
                <span class="ml-50">{{ data.value }}</span>
              </div>
            </template>
          </b-table>
        </b-row>
      </b-card-body>

      <b-card-footer class="py-75 border-0">
        <div class="d-flex justify-content-between d-flex align-items-center">
          <div class="d-flex align-items-center mb-0">
            <!-- <span class="text-nowrap"> Showing 1 to </span> -->
            <b-form-select
              size="sm"
              v-model="perPage"
              :options="['10', '25', '50']"
              class="mr-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap text-muted font-small-3">
              {{
                lang("t_total") +
                " " +
                items.length +
                " " +
                lang("t_row").toLowerCase()
              }}
            </span>
          </div>

          <b-pagination
            size="sm"
            class="mb-0"
            v-model="currentPage"
            :per-page="perPage"
            align="right"
            :total-rows="items.length"
            first-number
            last-number
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="16" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="16" />
            </template>
          </b-pagination>
        </div>
      </b-card-footer>
    </b-card>

    <b-modal
      size="lg"
      static
      v-model="modal_call_time_line"
      hide-footer
      centered
      ok-only
      :title="lang('t_callDetails')"
    >
      <div
        style="max-height: 40rem; overflow-y: auto"
        v-if="call_time_line.length > 0"
      >
        <app-timeline>
          <app-timeline-item
            v-for="(item, i) in call_time_line"
            :key="i"
            :title="lang(item.Event)"
            :subtitle="item.Data"
            :time="item.OccuranceTime"
            :variant="item.Color"
          />
        </app-timeline>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownForm,
  BImg,
  BButtonGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BTable,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  BFormCheckbox,
  BCardText,
  BBadge,
  BSpinner,
  BCardFooter,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BInputGroupPrepend,
  BContainer,
  VBTooltip
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "animate.css";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

const startDate = new Date();
startDate.setHours(0, 0, 0, 0);
const endDate = new Date();
endDate.setHours(23, 55, 0, 0);
export default {
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      is_progress: false,
      interval: {},
      excel_url: "",
      searchQuery: '',
      full_screen: false,
      filter_values: {
        dateRanges: {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        },
        selectedLines: [],
        selectedAgents: [],
        selectedAgentGroups: [],
        selectedQueues: [],
        selectedProjects: [],
        selectedQualityForms: [],
        selectedCampaigns: [],
        selectedCustomerColumns: [],
        selectedVerdicts: [],
        selectedTerminateSides: [],
        selectedDirections: [],
        selectedStates: [],
        selectedDynamicCRMColumns: {
          field1: "",
          field2: "",
        },
        selectedIvr: "",
        title: "",
        selectedNumber: "",
        selectedSL: 20,
        isFileNotNull: false,
        useDatesForEvaluateDate: false,
        dontGetNoQueueRecords: false,
      },
      StateOptions: [
        { internal_name: true, display_name: globalThis._lang('t_active') },
        { internal_name: false, display_name: globalThis._lang('t_passive') }
      ],
      grandTotal: null,
      audio_modal: false,
      audio2: "",
      htmlcontent: "",
      selected_note: "",
      selected_audio: "",
      finish_codes: [],
      agents: [],
      projects: [],
      queues: [],
      quality_forms: [],
      ivr_list: [],
      selected_date_filter_type: "today",
      selected_category: "queue",
      selected_report: "",
      selected_variant: "",
      categories: [
        { internal_name: "queue", display_name: globalThis._lang("t_queue") },
        { internal_name: "agent", display_name: globalThis._lang("t_agent") },
        { internal_name: "crm", display_name: globalThis._lang("t_crm") },
        { internal_name: "quality", display_name: globalThis._lang("t_quality") },
        { internal_name: "other", display_name: globalThis._lang("t_other") },
        // { internal_name: "callexper", display_name: "Callexper" },
      ],
      report_list: [],
      date_filter_types: [
        {
          internal_name: "today",
          display_name: globalThis._lang("t_today"),
        },
        {
          internal_name: "yesterday",
          display_name: globalThis._lang("t_yesterday"),
        },
        {
          internal_name: "last_7_days",
          display_name: globalThis._lang("t_last7Days"),
        },
        {
          internal_name: "last_30_days",
          display_name: globalThis._lang("t_last30Days"),
        },
        {
          internal_name: "this_month",
          display_name: globalThis._lang("t_thisMonth"),
        },
        {
          internal_name: "last_month",
          display_name: globalThis._lang("t_lastMonth"),
        },
        {
          internal_name: "between_dates",
          display_name: globalThis._lang("t_betweenDates"),
        },
      ],
      selected_time_format: 'second',
      report_time_types: [
        {
          internal_name: "hour",
          display_name: globalThis._lang("t_hour"),
        },
        {
          internal_name: "minute",
          display_name: globalThis._lang("t_minute"),
        },
        {
          internal_name: "second",
          display_name: globalThis._lang("t_second"),
        },
        {
          internal_name: "time",
          display_name: globalThis._lang("t_time"),
        }
      ],
      report_settings: {},
      perPage: 50,
      pageOptions: [10, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [],
      items: [],
      project_columns: [],
      report_permissions: [],
      column_labels: {
        customer_no: globalThis._lang('t_customerNo'),
        customer_name: globalThis._lang('t_customerName'),
        customer_identifier: globalThis._lang('t_customerIdentifier'),
        queue: globalThis._lang('t_queue'),
        active: globalThis._lang('t_state'),
        status: globalThis._lang('t_status'),
        assigned_agent: globalThis._lang('t_agent'),
        attempts: globalThis._lang('t_attempts'),
        total_attempts: globalThis._lang('t_totalAttempts'),
        campaign: globalThis._lang('t_campaign'),
        finish_code: globalThis._lang('t_finishCode'),
        insert_date: globalThis._lang('t_insertDate'),
        action_date: globalThis._lang('t_actionDate'),
        action_time: globalThis._lang('t_actionTime'),
        create_date: globalThis._lang('t_createDate'),
        create_time: globalThis._lang('t_createTime'),
        ex_agent: globalThis._lang('t_exAgent'),
        assign_date: globalThis._lang('t_assignDate'),
        new_assets: globalThis._lang('t_newAssets'),
        reached: globalThis._lang('t_reached'),
        reached_date: globalThis._lang('t_reachedDate'),
      },
      statu_description: {
        C: "Aranabilir",
        E: "Manual Olarak Elendi",
        I: "Aranıyor",
        K: "Arama Kuralına Göre Kapatıldı",
        U: "Ulaşıldı",
      },
      reports_in_queue_fields: [
        {
          key: "CreateDate",
          label: globalThis._lang("t_date"),
          sortable: true,
        },
        {
          key: "Username",
          label: globalThis._lang("t_creator"),
          sortable: true,
        },
        // {
        //   key: "Filter",
        //   label: globalThis._lang("t_filters"),
        //   sortable: true,
        // },
        {
          key: "ReportName",
          label: globalThis._lang("t_reportName"),
          sortable: true,
        },
        {
          key: "DataCount",
          label: globalThis._lang("t_dataCount"),
          sortable: true,
        },
        {
          key: "Status",
          label: globalThis._lang("t_status"),
          sortable: true,
        },
        {
          key: "Action",
          label: '',
          sortable: false,
          thStyle: { width: "5vh" },
        },
      ],
      reports_in_queue: [],
      colors: {
        Failed: "danger",
        "In Queue": "warning",
        "In Progress": "success",
        Done: "success",
      },
      description: {
        Failed: globalThis._lang("t_failed"),
        "In Queue": globalThis._lang("t_rInQueue"),
        "In Progress": globalThis._lang("t_rInProgress"),
        Done: globalThis._lang("t_isCompleted"),
      },
      PROTOCOL: globalThis.env.PROTOCOL,
      API_URL: globalThis.env.API_URL,
      call_time_line: [],
      visibleRows: [],
      modal_call_time_line: false,
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    AppTimeline,
    AppTimelineItem,
    BDropdown,
    BDropdownForm,
    BSpinner,
    BBadge,
    DateRangePicker,
    BImg,
    BFormCheckbox,
    BCardActions,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BTable,
    flatPickr,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    BCard,
    BCardText,
    vSelect,
    BButtonGroup,
    BCardFooter,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BInputGroupPrepend,
    BContainer,
  },
  computed: {

    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    filteredItems() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.items.slice(start, end);
    },
    getFullScreen() {
      if (!this.is_fullscreen) {
        return false;
      }
      console.log("this.is_fullscreen", this.is_fullscreen)
      console.log("this.title", this.title)
      console.log("this.title", this.sub_title)
      if (this.title == '' && this.sub_title == '') {
        console.log("GELDİ")
        return false;
      }
      return true;
    },
  },

  mounted() {
    this.is_progress = true;
    this.report_permissions = globalThis.permissions["report"] || [];

    this.GetReportsInQueue();

    const this_data = JSON.parse(localStorage.getItem("report_cache"));
    if (![undefined, null].includes(this_data)) {
      for (let [key, val] of Object.entries(this_data)) {
        this[key] = val;
      }
    }
    this.fields = JSON.parse(JSON.stringify(this.fields));
    this.full_screen = false;

    this.GetReportSettings();
    this.getCustomerColumns();
    this.GetReports();
    this.get_users();
    this.GetAgentGroup();
    this.GetProjects();
    this.GetQueues();
    this.GetCampaigns();
    this.GetQualityForms();
    this.GetIVR();
    this.GetLine();
    this.GetFinishCodes();

    let _self = this;
    this.interval = setInterval(() => {
      _self.GetReportsInQueue();
    }, 30000);

    this.is_progress = false;
    // this.GetInboundRouteTargets();
  },
  beforeDestroy() {
    clearInterval(this.interval);
    localStorage.setItem("report_cache", JSON.stringify(this.$data));
  },
  methods: {
    onAgentGroupChange() {
      this.filter_values.selectedAgents = [];
      let _agents = [...new Set(this.filter_values.selectedAgentGroups.flatMap(e => e.agents.map(j => j.split('@')[0])))];
      console.log("_agents", _agents);
      this.filter_values.selectedAgents = _agents;
    },
    calculateSum(fieldKey) {
      return this.visibleRows.reduce((sum, item) => sum + parseFloat(item[fieldKey]), 0).toFixed(2);
    },
    calculateAverage(fieldKey) {
      const total = this.calculateSum(fieldKey);
      return (total / this.visibleRows.length).toFixed(2);
    },

    async showCallDetails(item) {
      if (this.selected_report.internal_name == 'CallDetails') {
        this.is_progress = true;

        this.call_time_line = (await this.$http_in.post(`report/v1/CallDetails/${item.InteractionID}`)).data;
        this.modal_call_time_line = true;
      }
      this.is_progress = false;

    },
    async GetReportSettings() {
      this.report_settings = (await this.$http_in.get(`report/v1/ReportSettings`)).data;
    },
    async SaveReportFields() {
      var response = (await this.$http_in.put(`report/v1/ReportSettings`, {
        username: globalThis.user.username,
        domain: globalThis.user.domain,
        report_name: this.selected_report.internal_name,
        fields: this.fields.filter(e => e.visible == true).map(e => e.key),
      })).data;

      await this.GetReportSettings();
    },
    async SaveReportFilters() {
      var response = (await this.$http_in.put(`report/v1/ReportSettings`, {
        report_name: this.selected_report.internal_name,
        filter_values: this.filter_values,
      })).data;

      await this.GetReportSettings();

      if (response && response.acknowledged) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    },

    customClickRange(range) {
      let startDate = range[0];
      let endDate = new Date(range[1]);
      endDate.setHours(23, 59, 59, 0);
      this.filter_values.dateRanges = { startDate, endDate };
    },
    fieldsVisibiltyChanged() {
      this.fields = JSON.parse(JSON.stringify(this.fields));


    },
    GetReportsInQueue: async function () {
      var response = (await this.$http_in.get(`report/v1/ReportsInQueue`)).data;

      this.reports_in_queue = response;
    },

    setDateFormat(date_string) {
      const date_object = new Date(date_string);

      const day = String(date_object.getDate()).padStart(2, '0');
      const month = String(date_object.getMonth() + 1).padStart(2, '0');
      const year = date_object.getFullYear();
      const hour = String(date_object.getHours()).padStart(2, '0');
      const minute = String(date_object.getMinutes()).padStart(2, '0');

      const formatted_date = `${day}/${month}/${year} ${hour}:${minute}`;
      return formatted_date;
    },
    formatName(value) {
      return value || "-";
    },
    clear_report() {
      this.fields = [];
      this.items = [];
      this.filter_values = {
        ...this.filter_values,
        // startDate: new Date(new Date((new Date().setHours(0, 0, 0, 0))).getTime() + 3 * 60 * 60 * 1000).toISOString().replace(/([^T]+)T([^\.]+).*/g, '$1 $2'),
        // endDate: new Date(new Date((new Date().setHours(23, 59, 59, 0))).getTime() + 3 * 60 * 60 * 1000).toISOString().replace(/([^T]+)T([^\.]+).*/g, '$1 $2'),
        dateRanges: {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        },
        selectedLines: [],
        selectedAgents: [],
        selectedAgentGroups: [],
        selectedQueues: [],
        selectedProjects: [],
        selectedQualityForms: [],
        selectedCampaigns: [],
        selectedCustomerColumns: [],
        selectedVerdicts: [],
        selectedTerminateSides: [],
        selectedDirections: [],
        selectedStates: [],
        selectedDynamicCRMColumns: {
          field1: "",
          field2: "",
        },
        selectedIvr: "",
        title: "",
        selectedNumber: "",
        selectedSL: 20,
        isFileNotNull: false,
      };

      this.selected_report = "";
    },
    clear_data() {
      this.selected_variant = '';
      this.fields = [];
      this.items = [];
      // this.selected_date_filter_type == 'today';
      this.filter_values = {
        ...this.filter_values,
        // startDate: new Date(new Date((new Date().setHours(0, 0, 0, 0))).getTime() + 3 * 60 * 60 * 1000).toISOString().replace(/([^T]+)T([^\.]+).*/g, '$1 $2'),
        // endDate: new Date(new Date((new Date().setHours(23, 59, 59, 0))).getTime() + 3 * 60 * 60 * 1000).toISOString().replace(/([^T]+)T([^\.]+).*/g, '$1 $2'),
        dateRanges: {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        },
        selectedLines: [],
        selectedAgents: [],
        selectedAgentGroups: [],
        selectedQueues: [],
        selectedProjects: [],
        selectedCampaigns: [],
        selectedVerdicts: [],
        selectedTerminateSides: [],
        selectedDirections: [],
        selectedStates: [],
        selectedCustomerColumns: [],
        selectedDynamicCRMColumns: {
          field1: "",
          field2: "",
        },
        selectedIvr: "",
        title: "",
        selectedNumber: "",
        selectedSL: 20,
        isFileNotNull: false,
      };

      let _report = this.report_settings.find(e => e.report_name == this.selected_report.internal_name);
      if (_report) {
        this.filter_values = { ...this.filter_values, ..._report.filter_values };
      }
    },

    getFilterOptions() {
      if (this.selected_category == "project") {
        return this.projects;
      }
      if (this.selected_category == "queue") {
        return this.queues;
      }
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getReportData: async function () {
      try {
        this.is_progress = true;

        if ([null, undefined, ""].includes(this.selected_report)) {
          this.$bvToast.toast(`${globalThis._lang("t_pleaseSelectReport")}`, {
            title: `${globalThis._lang("t_notification")}`,
            toaster: "b-toaster-bottom-right",
            solid: true,
            appendToast: false,
            variant: "warning",
          });
          return;
        }

        this.excel_url = "";
        // console.log(this.selected_report);
        this.fields = this.selected_report.fields;
        let tmp_fields = [];
        for (const item of this.fields) {
          tmp_fields.push({
            ...item,
            label: globalThis._lang(item.label),
          });
        }

        console.log("this.filter_values.selectedQueues.length", this.filter_values.selectedQueues.length)
        console.log("this.queues", this.queues)
        console.log("this.selected_time_format", this.selected_time_format)


        let url = `${globalThis.env.PROTOCOL}://${globalThis.env.API_URL}/report/v1/${this.selected_report.internal_name}`;
        if (![null, undefined, ''].includes(this.selected_variant)) {
          url += `/${this.selected_variant}`
        }
        if (this.selected_report.base_url) {
          url = `${globalThis.env.PROTOCOL}://${globalThis.env.API_URL.replace(
            "api",
            this.selected_report.base_url
          )}/callexper/v1/${this.selected_report.internal_name}`;
        }
        var resp =
          await this.$http_in.post(url, {
            filters: {
              ...this.filter_values,
              selectedQueues: this.filter_values.selectedQueues.length == 0 ? this.queues.map(e => e.internal_name) : this.filter_values.selectedQueues
            },
            fields: tmp_fields,
            time_format: this.selected_time_format,
            report_name: this.selected_report.internal_name,
            date_limit: this.selected_report.date_limit,
            data_limit: this.selected_report.data_limit,
          });

        if (resp.status == 202) {
          this.$bvToast.toast(
            `${globalThis._lang("t_yourReportAddToReportQueue")}`,
            {
              title: `${globalThis._lang("t_notification")}`,
              toaster: "b-toaster-bottom-right",
              solid: true,
              appendToast: false,
              variant: "warning",
            }
          );
          this.GetReportsInQueue();
          return;
        }

        console.log("resp", resp)
        var response = resp.data;
        this.grandTotal = response?.grandTotal ?? null;
        this.excel_url = response.url;
        this.items = response.data;
        if (response.extra_fields.length > 0) {
          if (
            this.selected_report.internal_name == "DataSummaryByDynamicFields"
          ) {
            this.fields = [...response.extra_fields, ...this.fields];
          } else {
            this.fields = [...this.fields, ...response.extra_fields];
          }
        }


        let _report_cols = this.report_settings.find(e => e.report_name == this.selected_report.internal_name);

        for (const col of this.fields) {
          if (_report_cols && _report_cols.fields) {
            if (_report_cols.fields.includes(col.key)) {
              col.visible = true;
            }
            else {
              col.visible = false;
            }
          } else {
            col.visible = true;
          }

          col.tdClass = 'text-nowrap';
          if (Object.keys(this.column_labels).includes(col.label)) {
            col.label = this.column_labels[col.label];
          } else {
            col.label = globalThis._lang(col.label);
          }
          try {
            col.label = typeof col.label == "string" ? col.label.replace(/ /g, "\u00a0") : col.label;
          } catch (error) {
            console.log("col.label", col.label);
            console.log(error);
          }
        }
        this.fields = JSON.parse(JSON.stringify(this.fields));


        if (response.data.length >= 500) {
          // this.$bvToast.toast(
          //   `${globalThis._lang("t_yourReportAddToReportQueue")}`,
          //   {
          //     title: `${globalThis._lang("t_notification")}`,
          //     toaster: "b-toaster-bottom-right",
          //     solid: true,
          //     appendToast: false,
          //     variant: "warning",
          //   }
          // );
          this.GetReportsInQueue();
        }
      } catch (error) {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        console.log(error);
      } finally {
        this.is_progress = false;
      }

      // this.dialog = false;
      // this.is_progress = false;
    },
    get_users: async function () {
      var response = (await this.$http_in.get(`report/v1/User`)).data;

      this.agents = response;
    },
    GetAgentGroup: async function () {
      var response = (await this.$http_in.get(`/optmgr/v1/AgentGroup`)).data;

      this.agent_groups = response;
    },

    GetFinishCodes: async function () {
      var response = (await this.$http_in.get(`spv/v1/FinishCodes`)).data;

      this.finish_codes = response;
      this.finish_codes.push({ finish_code: 'Abandoned' });
      this.finish_codes.push({ finish_code: 'HangupInIVR' });
      this.finish_codes.push({ finish_code: 'Success' });
      this.finish_codes.push({ finish_code: 'No Action' });
      this.finish_codes.push({ finish_code: 'AgentConnectionLost' });
      this.finish_codes.push({ finish_code: 'RemoteConnectionLost' });
    },
    secondsToHHMMSS(seconds) {
      return (
        Math.floor(seconds / 3600) +
        ":" +
        ("0" + (Math.floor(seconds / 60) % 60)).slice(-2) +
        ":" +
        ("0" + (seconds % 60)).slice(-2)
      );
    },
    GetProjects: async function () {
      var response = (await this.$http_in.get(`report/v1/Project`)).data;

      this.projects = response;
    },
    GetQueues: async function () {
      var response = (await this.$http_in.get(`spv/v1/Queue`)).data;
      // console.log('queues', response);
      this.queues = [];
      let p_queues = globalThis.permissions["queue"];
      // this.all_queues = response;
      for (const item of response) {
        if (p_queues.includes(item.internal_name)) {
          this.queues.push(item);
        }
      }
    },
    GetCampaigns: async function () {
      var response = (await this.$http_in.get(`report/v1/Campaign`)).data;

      this.campaigns = response;
    },
    GetQualityForms: async function () {
      var response = (await this.$http_in.get(`report/v1/QualityForm`)).data;

      this.quality_forms = response;
    },
    getCustomerColumns: async function () {
      // //console.log('this.agent.Project.INTERNAL_NAME', this.agent);
      var response = (await this.$http_in.get(`crm/v1/CustomerColumns`)).data;
      this.project_columns = [];
      this.columns = response[0].allkeys.sort();
      for (const item of response[0].allkeys) {
        if (
          [
            "active",
            "_id",
            "notes",
            "notes",
            "customer_identifier_masked",
            "files",
            "view_moment",
            "products",
            "extra_data",
            "sms",
            "attempts",
            "campaign",
          ].includes(item)
        ) {
          continue;
        }
        this.project_columns.push({
          text:
            this.column_labels[item] == undefined
              ? item
              : this.column_labels[item],
          value: item,
        });
      }
    },
    GetIVR: async function () {
      var response = (await this.$http_in.get(`report/v1/Ivr`)).data;

      this.ivr_list = response.filter(e => e.type != 'transfer');
    },
    GetLine: async function () {
      var response = (await this.$http_in.get(`report/v1/Line`)).data;

      this.lines = response;
    },
    GetReports: async function () {
      var response = (await this.$http_in.get(`report/v1/Reports`)).data;

      this.report_list = [];
      for (const item of response) {
        if (this.report_permissions.includes(item.internal_name)) {
          item.display_name = globalThis._lang(item.display_name);
          if (item.variants) {
            for (const _variant of item.variants) {
              _variant.display_name = globalThis._lang(_variant.display_name);
            }
          }
          this.report_list.push(item);
        }
      }
      console.log("report_list", this.report_list);
    },
    close_modal() {
      this.audio2 = document.getElementById("voyce_audio");
      this.audio2.pause();
      this.selected_audio = "";
    },
    openAudio(item) {
      // console.log(item);

      this.selected_audio = `${globalThis.env["PROTOCOL"]}://${globalThis.env.RECORDING_URL}/recordings/${item.RecordingFile}`;

      // if (this.$refs.player != undefined) {
      //   this.$refs.player.player.currentSrc = this.selected_audio;
      // }

      this.htmlcontent = `<!DOCTYPE html>
    <html>
        <head>
         <meta charset="utf-8" />
            <title>${item.Verdict} - ${item.BaseRemoteNumber}</title>
        </head>
        <body>
        <h4>[Tarih] => ${item.StartTime}</h4>
        <h4>[Telefon] =>  ${item.BaseRemoteNumber}</h4>
        <h4>[Sonuç Kodu] => ${item.Verdict}</h4>
        <h4>[Temsilci] => ${item.Agent}</h4>
        <h4>[Kuyruk] => ${this.queues.find((e) => e.internal_name == item.Queue)
          ? this.queues.find((e) => e.internal_name == item.Queue)
            .display_name
          : item.Queue
        }</h4>
           <audio id="voyce_audio" ref="player" controls ${globalThis.v_navbar.other_permission.includes("download_sound")
          ? ""
          : 'controlsList = "nodownload"'
        } preload="none" style = "width:100%">
              <source src="${this.selected_audio}" type="audio/mpeg">
              Your browser does not support the audio element.
           </audio>
      ${globalThis.script}
           
        </body>
    </html>
      `;
      const winUrl = URL.createObjectURL(
        new Blob([this.htmlcontent], { type: "text/html" })
      );
      window.open(
        winUrl,
        "_blank",
        `width=800,height=400,screenX=200,screenY=200`
      );
      return;
      // window.open(this.selected_audio, item.BaseRemoteNumber, "_blank");

      this.audio_modal = true;
      this.$swal({
        title: `<span class="font-weight-bolder">${item.Verdict}</span><br><span class="font-weight-bolder">  ${item.BaseRemoteNumber}</span>`,
        html: this.htmlcontent,
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: "Tamam",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        showClass: {
          popup: "animate__animated animate__flipInX",
        },
        buttonsStyling: false,
        onClose: this.close_modal,
      });
    },
    openSecLineAudio(item) {
      // console.log(item);

      this.selected_audio = `${globalThis.env["PROTOCOL"]}://${globalThis.env.RECORDING_URL}/secondline/${item.RecordingFilePath}`;

      // if (this.$refs.player != undefined) {
      //   this.$refs.player.player.currentSrc = this.selected_audio;
      // }

      this.htmlcontent = `<!DOCTYPE html>
    <html>
        <head>
         <meta charset="utf-8" />
            <title>${item.RemoteNumber}</title>
        </head>
        <body>
        <h4>[Tarih] => ${item.StartTime}</h4>
        <h4>[Telefon] =>  ${item.RemoteNumber}</h4>
        <h4>[Dahili] => ${item.LocalNumber}</h4>
           <audio id="voyce_audio" ref="player" controls ${globalThis.v_navbar.other_permission.includes("download_sound")
          ? ""
          : 'controlsList = "nodownload"'
        } preload="none" style = "width:100%">
              <source src="${this.selected_audio}" type="audio/mpeg">
              Your browser does not support the audio element.
           </audio>
      ${globalThis.script}
           
        </body>
    </html>
      `;
      const winUrl = URL.createObjectURL(
        new Blob([this.htmlcontent], { type: "text/html" })
      );
      window.open(
        winUrl,
        "_blank",
        `width=800,height=400,screenX=200,screenY=200`
      );
      return;
      // window.open(this.selected_audio, item.BaseRemoteNumber, "_blank");

      this.audio_modal = true;
      this.$swal({
        title: `<span class="font-weight-bolder">${item.Verdict}</span><br><span class="font-weight-bolder">  ${item.BaseRemoteNumber}</span>`,
        html: this.htmlcontent,
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: "Tamam",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        showClass: {
          popup: "animate__animated animate__flipInX",
        },
        buttonsStyling: false,
        onClose: this.close_modal,
      });
    },
    openNote(item) {
      this.$swal({
        title: item.Note ?? item.note ?? '',
        customClass: {
          confirmButton: "btn btn-primary",
        },
        showClass: {
          popup: "animate__animated animate__bounceIn",
        },
        buttonsStyling: false,
      });
    },
    async onexport() {
      // if (this.excel_url != '') {
      //   // window.location.assign(this.excel_url);
      //   this.is_progress = true;
      //   try {
      //     var response = await this.$http_ex.get(this.excel_url);
      //     console.log("response.status", response.status);
      //     if (response.status == 200) {
      //       var anchor = document.createElement('a');
      //       anchor.href = this.excel_url;
      //       anchor.download = this.excel_url;
      //       anchor.target = '_blank';
      //       document.body.appendChild(anchor);
      //       anchor.click();
      //       this.is_progress = false;
      //       return;
      //     }
      //     else {
      //       this.onexport();
      //     }
      //   } catch (error) {
      //     this.onexport();
      //   }

      //   // await new Promise(res => setTimeout(res, 10000));

      // }
      // return;
      // On Click Excel download button
      let _headers = {};
      for (const header of this.fields.filter(field => field.visible == true)) {
        _headers[header.key] = header.label;
      }
      console.log("_headers", _headers);
      console.log("this.fields", this.fields);

      let tmp_data = [...this.items];
      if (this.grandTotal) {
        tmp_data.push(this.grandTotal);
      }
      let _data = [];
      for (const row of tmp_data) {
        let obj = {};

        for (const column of Object.keys(_headers)) {
          let key = _headers[column] == undefined ? column : _headers[column];
          obj[key] = row[column];
        }
        _data.push(obj);
      }



      var data = this.$xlsx.utils.json_to_sheet(_data);

      // A workbook is the name given to an Excel file
      var wb = this.$xlsx.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      this.$xlsx.utils.book_append_sheet(
        wb,
        data,
        `${this.selected_report.display_name.split("/").join("-")}`
      ); // sheetAName is name of Worksheet

      // export Excel file
      this.$xlsx.writeFile(
        wb,
        `${this.selected_report.display_name.split("/").join("-")}_${new Date().toISOString().split('T')[0]}.xlsx`
      ); // name of the file is 'book.xlsx'
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.table-responsive {
  overflow: unset;
}

.daterangepicker {
  font-family: "Montserrat", "OpenSans", Helvetica, Arial, serif;
}
.daterangepicker td.in-range {
  background-color: #bfb9fa;
  border-color: transparent;
  color: #000;
  border-radius: 0;
}
.reportrange-text {
  padding: 8px 10px !important;
}

.daterangepicker td.active:hover {
  background-color: #7367f0;
  border-color: transparent;
  color: #fff;
}

.daterangepicker .ranges li.active {
  background-color: #7367f0;
  color: #fff;
}

.daterangepicker select.hourselect {
  text-align: center;
}
.daterangepicker select.minuteselect {
  text-align: center;
}
.form-control {
  -webkit-appearance: auto !important;
}
.test {
  white-space: nowrap !important;
}
</style>

